/* tslint:disable */
/* eslint-disable */
/**
 * WineMS.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    addressIdNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    streetAndNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postalCode?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserBaseDto
 */
export interface ApplicationUserBaseDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserBaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserBaseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserBaseDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserBaseDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserBaseDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserBaseDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserBaseDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserBaseDto
     */
    emailConfirmed?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    auth0Id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    withInvitation?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserRegisterDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    auth0Id?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserRole {
    Admin = 'Admin',
    UserManager = 'UserManager'
}

/**
 * 
 * @export
 * @interface ApplicationUserUpdateHistoryCreateDto
 */
export interface ApplicationUserUpdateHistoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryCreateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserUpdateHistoryDetailsDto
 */
export interface ApplicationUserUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDetailsDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserUpdateHistoryDto
 */
export interface ApplicationUserUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserUpdateHistoryUpdateDto
 */
export interface ApplicationUserUpdateHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserUpdateHistoryUpdateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface BatchNumberDto
 */
export interface BatchNumberDto {
    /**
     * 
     * @type {number}
     * @memberof BatchNumberDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchNumberDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchNumberDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchNumberDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BatchNumberDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BatchNumberDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BatchNumberDto
     */
    unitId?: number;
    /**
     * 
     * @type {UnitOfMeasureDto}
     * @memberof BatchNumberDto
     */
    unit?: UnitOfMeasureDto;
    /**
     * 
     * @type {number}
     * @memberof BatchNumberDto
     */
    quantity?: number;
}
/**
 * 
 * @export
 * @interface CompanyDataCreateDto
 */
export interface CompanyDataCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    taxIdentificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataCreateDto
     */
    bankAccountNumber?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataDetailsDto
 */
export interface CompanyDataDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    taxIdentificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDetailsDto
     */
    bankAccountNumber?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataDto
 */
export interface CompanyDataDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    taxIdentificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataDto
     */
    bankAccountNumber?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataMinimalDto
 */
export interface CompanyDataMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataMinimalDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataUpdateDto
 */
export interface CompanyDataUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    taxIdentificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateDto
     */
    bankAccountNumber?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataUpdateHistoryCreateDto
 */
export interface CompanyDataUpdateHistoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    companyDataId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryCreateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataUpdateHistoryDetailsDto
 */
export interface CompanyDataUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    companyDataId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDetailsDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataUpdateHistoryDto
 */
export interface CompanyDataUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof CompanyDataUpdateHistoryDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof CompanyDataUpdateHistoryDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryDto
     */
    companyDataId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDataUpdateHistoryUpdateDto
 */
export interface CompanyDataUpdateHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    companyDataId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDataUpdateHistoryUpdateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ContractorCreateDto
 */
export interface ContractorCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreateDto
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorCreateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof ContractorCreateDto
     */
    mainAddress?: AddressDto;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof ContractorCreateDto
     */
    addresses?: Array<AddressDto> | null;
}
/**
 * 
 * @export
 * @interface ContractorDetailsDto
 */
export interface ContractorDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDetailsDto
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof ContractorDetailsDto
     */
    mainAddress?: AddressDto;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof ContractorDetailsDto
     */
    addresses?: Array<AddressDto> | null;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof ContractorDetailsDto
     */
    stockOperations?: Array<StockOperationDto> | null;
}
/**
 * 
 * @export
 * @interface ContractorDto
 */
export interface ContractorDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorDto
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof ContractorDto
     */
    mainAddress?: AddressDto;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof ContractorDto
     */
    addresses?: Array<AddressDto> | null;
}
/**
 * 
 * @export
 * @interface ContractorUpdateDto
 */
export interface ContractorUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateDto
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorUpdateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof ContractorUpdateDto
     */
    mainAddress?: AddressDto;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof ContractorUpdateDto
     */
    addresses?: Array<AddressDto> | null;
}
/**
 * 
 * @export
 * @interface ContractorUpdateHistoryCreateDto
 */
export interface ContractorUpdateHistoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    contractorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryCreateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ContractorUpdateHistoryDetailsDto
 */
export interface ContractorUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    contractorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDetailsDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ContractorUpdateHistoryDto
 */
export interface ContractorUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ContractorUpdateHistoryDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ContractorUpdateHistoryDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryDto
     */
    contractorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ContractorUpdateHistoryUpdateDto
 */
export interface ContractorUpdateHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    contractorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractorUpdateHistoryUpdateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface CropCreateDto
 */
export interface CropCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CropCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CropCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CropCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CropCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CropCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CropCreateDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface CropDetailsDto
 */
export interface CropDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CropDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CropDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CropDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CropDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CropDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CropDetailsDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface CropDto
 */
export interface CropDto {
    /**
     * 
     * @type {number}
     * @memberof CropDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CropDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CropDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CropDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CropDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CropDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface CropUpdateDto
 */
export interface CropUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CropUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CropUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CropUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CropUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CropUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CropUpdateDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface InventoryOperationCreateDto
 */
export interface InventoryOperationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationCreateDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof InventoryOperationCreateDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationCreateDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationCreateDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<InventoryOperationDetailDto>}
     * @memberof InventoryOperationCreateDto
     */
    inventoryOperationDetails?: Array<InventoryOperationDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationCreateDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationCreateDto
     */
    documentNumber?: string | null;
}
/**
 * 
 * @export
 * @interface InventoryOperationDetailDto
 */
export interface InventoryOperationDetailDto {
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    inventoryOperationId?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailDto
     */
    batchNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    unitId?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailDto
     */
    quantity?: number;
}
/**
 * 
 * @export
 * @interface InventoryOperationDetailsDto
 */
export interface InventoryOperationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailsDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof InventoryOperationDetailsDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailsDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<InventoryOperationDetailDto>}
     * @memberof InventoryOperationDetailsDto
     */
    inventoryOperationDetails?: Array<InventoryOperationDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDetailsDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDetailsDto
     */
    documentNumber?: string | null;
}
/**
 * 
 * @export
 * @interface InventoryOperationDto
 */
export interface InventoryOperationDto {
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof InventoryOperationDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<InventoryOperationDetailDto>}
     * @memberof InventoryOperationDto
     */
    inventoryOperationDetails?: Array<InventoryOperationDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationDto
     */
    documentNumber?: string | null;
}
/**
 * 
 * @export
 * @interface InventoryOperationUpdateDto
 */
export interface InventoryOperationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationUpdateDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof InventoryOperationUpdateDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationUpdateDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationUpdateDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<InventoryOperationDetailDto>}
     * @memberof InventoryOperationUpdateDto
     */
    inventoryOperationDetails?: Array<InventoryOperationDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryOperationUpdateDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryOperationUpdateDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryOperationUpdateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Create = 'Create',
    Delete = 'Delete',
    Edit = 'Edit',
    Assign = 'Assign'
}

/**
 * 
 * @export
 * @interface ProductCreateDto
 */
export interface ProductCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {UnitOfMeasureDto}
     * @memberof ProductCreateDto
     */
    baseUnit?: UnitOfMeasureDto;
    /**
     * 
     * @type {Array<SimpleBaseUnitDto>}
     * @memberof ProductCreateDto
     */
    auxiliaryUnits?: Array<SimpleBaseUnitDto> | null;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductCreateDto
     */
    type?: ProductType;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    baseUnitId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    auxiliaryUnitsIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductDetailsDto
 */
export interface ProductDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    baseUnitId?: number;
    /**
     * 
     * @type {UnitOfMeasureDto}
     * @memberof ProductDetailsDto
     */
    baseUnit?: UnitOfMeasureDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    auxiliaryUnitsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<SimpleBaseUnitDto>}
     * @memberof ProductDetailsDto
     */
    auxiliaryUnits?: Array<SimpleBaseUnitDto> | null;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductDetailsDto
     */
    type?: ProductType;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    baseUnitId?: number;
    /**
     * 
     * @type {UnitOfMeasureDto}
     * @memberof ProductDto
     */
    baseUnit?: UnitOfMeasureDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    auxiliaryUnitsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<SimpleBaseUnitDto>}
     * @memberof ProductDto
     */
    auxiliaryUnits?: Array<SimpleBaseUnitDto> | null;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductDto
     */
    type?: ProductType;
}
/**
 * 
 * @export
 * @interface ProductMinimalDto
 */
export interface ProductMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductMinimalDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductType {
    Wine = 'Wine',
    Fruit = 'Fruit',
    Juice = 'Juice',
    Sediment = 'Sediment',
    Pomace = 'Pomace',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface ProductUpdateDto
 */
export interface ProductUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    baseUnitId?: number;
    /**
     * 
     * @type {UnitOfMeasureDto}
     * @memberof ProductUpdateDto
     */
    baseUnit?: UnitOfMeasureDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    auxiliaryUnitsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<SimpleBaseUnitDto>}
     * @memberof ProductUpdateDto
     */
    auxiliaryUnits?: Array<SimpleBaseUnitDto> | null;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductUpdateDto
     */
    type?: ProductType;
}
/**
 * 
 * @export
 * @interface ProductUpdateHistoryCreateDto
 */
export interface ProductUpdateHistoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryCreateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryCreateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ProductUpdateHistoryCreateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ProductUpdateHistoryCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryCreateDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryCreateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ProductUpdateHistoryDetailsDto
 */
export interface ProductUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDetailsDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ProductUpdateHistoryDto
 */
export interface ProductUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ProductUpdateHistoryDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ProductUpdateHistoryDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ProductUpdateHistoryUpdateDto
 */
export interface ProductUpdateHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateHistoryUpdateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface ProductionProcessCreateDto
 */
export interface ProductionProcessCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessCreateDto
     */
    date?: string;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof ProductionProcessCreateDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof ProductionProcessCreateDto
     */
    stockOperations?: Array<StockOperationDto> | null;
}
/**
 * 
 * @export
 * @interface ProductionProcessDetailsDto
 */
export interface ProductionProcessDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof ProductionProcessDetailsDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof ProductionProcessDetailsDto
     */
    stockOperations?: Array<StockOperationDto> | null;
}
/**
 * 
 * @export
 * @interface ProductionProcessDto
 */
export interface ProductionProcessDto {
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessDto
     */
    date?: string;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof ProductionProcessDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof ProductionProcessDto
     */
    stockOperations?: Array<StockOperationDto> | null;
}
/**
 * 
 * @export
 * @interface ProductionProcessUpdateDto
 */
export interface ProductionProcessUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductionProcessUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionProcessUpdateDto
     */
    date?: string;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof ProductionProcessUpdateDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof ProductionProcessUpdateDto
     */
    stockOperations?: Array<StockOperationDto> | null;
}
/**
 * 
 * @export
 * @interface SimpleBaseUnitDto
 */
export interface SimpleBaseUnitDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleBaseUnitDto
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleBaseUnitDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleBaseUnitDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleBaseUnitDto
     */
    conversionToBase?: number | null;
}
/**
 * 
 * @export
 * @interface StockDto
 */
export interface StockDto {
    /**
     * 
     * @type {number}
     * @memberof StockDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {number}
     * @memberof StockDto
     */
    productId?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof StockDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {string}
     * @memberof StockDto
     */
    batchNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockDto
     */
    unitId?: number;
    /**
     * 
     * @type {SimpleBaseUnitDto}
     * @memberof StockDto
     */
    unit?: SimpleBaseUnitDto;
    /**
     * 
     * @type {number}
     * @memberof StockDto
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StockDto
     */
    reservedQuantity?: number;
}
/**
 * 
 * @export
 * @interface StockHistoryMinimalDto
 */
export interface StockHistoryMinimalDto {
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockHistoryMinimalDto
     */
    operationType?: StockOperationType;
    /**
     * 
     * @type {string}
     * @memberof StockHistoryMinimalDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockHistoryMinimalDto
     */
    batchNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockHistoryMinimalDto
     */
    unitCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockHistoryMinimalDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof StockHistoryMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockHistoryMinimalDto
     */
    stockOperationId?: number;
}
/**
 * 
 * @export
 * @interface StockOperationCreateDto
 */
export interface StockOperationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    warehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationCreateDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    contractorId?: number | null;
    /**
     * 
     * @type {ContractorDto}
     * @memberof StockOperationCreateDto
     */
    contractor?: ContractorDto;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationCreateDto
     */
    type?: StockOperationType;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    destinationWarehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationCreateDto
     */
    destinationWarehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof StockOperationCreateDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationCreateDto
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockOperationCreateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<StockOperationDetailDto>}
     * @memberof StockOperationCreateDto
     */
    stockOperationDetails?: Array<StockOperationDetailDto> | null;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof StockOperationCreateDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof StockOperationCreateDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    parentStockOperationId?: number | null;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof StockOperationCreateDto
     */
    stockOperations?: Array<StockOperationDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationCreateDto
     */
    productionProcessId?: number | null;
}
/**
 * 
 * @export
 * @interface StockOperationDetailDto
 */
export interface StockOperationDetailDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailDto
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {ProductDto}
     * @memberof StockOperationDetailDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {boolean}
     * @memberof StockOperationDetailDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<BatchNumberDto>}
     * @memberof StockOperationDetailDto
     */
    batchNumbers?: Array<BatchNumberDto> | null;
}
/**
 * 
 * @export
 * @interface StockOperationDetailsDto
 */
export interface StockOperationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    warehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationDetailsDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    contractorId?: number | null;
    /**
     * 
     * @type {ContractorDto}
     * @memberof StockOperationDetailsDto
     */
    contractor?: ContractorDto;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationDetailsDto
     */
    type?: StockOperationType;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    destinationWarehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationDetailsDto
     */
    destinationWarehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailsDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockOperationDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<StockOperationDetailDto>}
     * @memberof StockOperationDetailsDto
     */
    stockOperationDetails?: Array<StockOperationDetailDto> | null;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof StockOperationDetailsDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDetailsDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    parentStockOperationId?: number | null;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof StockOperationDetailsDto
     */
    stockOperations?: Array<StockOperationDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDetailsDto
     */
    productionProcessId?: number | null;
}
/**
 * 
 * @export
 * @interface StockOperationDto
 */
export interface StockOperationDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    warehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    contractorId?: number | null;
    /**
     * 
     * @type {ContractorDto}
     * @memberof StockOperationDto
     */
    contractor?: ContractorDto;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationDto
     */
    type?: StockOperationType;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    destinationWarehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationDto
     */
    destinationWarehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDto
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockOperationDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<StockOperationDetailDto>}
     * @memberof StockOperationDto
     */
    stockOperationDetails?: Array<StockOperationDetailDto> | null;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof StockOperationDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof StockOperationDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    parentStockOperationId?: number | null;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof StockOperationDto
     */
    stockOperations?: Array<StockOperationDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationDto
     */
    productionProcessId?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StockOperationHistoryValues {
    Date = 'Date',
    Type = 'Type',
    Status = 'Status',
    Product = 'Product',
    Quantity = 'Quantity',
    Operation = 'Operation',
    Contractor = 'Contractor',
    BatchNumber = 'BatchNumber',
    DocumentNumber = 'DocumentNumber',
    StockOperationDetail = 'StockOperationDetail'
}

/**
 * 
 * @export
 * @interface StockOperationMinimalDto
 */
export interface StockOperationMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationMinimalDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationMinimalDto
     */
    contractor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationMinimalDto
     */
    date?: string;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationMinimalDto
     */
    type?: StockOperationType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StockOperationStatus {
    Draft = 'Draft',
    ForCompletion = 'ForCompletion',
    Published = 'Published'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum StockOperationType {
    Pz = 'PZ',
    Wz = 'WZ',
    Mm = 'MM',
    Pw = 'PW',
    Rw = 'RW',
    Opp = 'OPP',
    Opw = 'OPW',
    Oi = 'OI'
}

/**
 * 
 * @export
 * @interface StockOperationUpdateDto
 */
export interface StockOperationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    warehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationUpdateDto
     */
    warehouse?: WarehouseDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    contractorId?: number | null;
    /**
     * 
     * @type {ContractorDto}
     * @memberof StockOperationUpdateDto
     */
    contractor?: ContractorDto;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationUpdateDto
     */
    type?: StockOperationType;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    destinationWarehouseId?: number | null;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof StockOperationUpdateDto
     */
    destinationWarehouse?: WarehouseDto;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateDto
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockOperationUpdateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<StockOperationDetailDto>}
     * @memberof StockOperationUpdateDto
     */
    stockOperationDetails?: Array<StockOperationDetailDto> | null;
    /**
     * 
     * @type {StockOperationStatus}
     * @memberof StockOperationUpdateDto
     */
    status?: StockOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    parentStockOperationId?: number | null;
    /**
     * 
     * @type {Array<StockOperationDto>}
     * @memberof StockOperationUpdateDto
     */
    stockOperations?: Array<StockOperationDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateDto
     */
    productionProcessId?: number | null;
}
/**
 * 
 * @export
 * @interface StockOperationUpdateHistoryCreateDto
 */
export interface StockOperationUpdateHistoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    stockOperationDetailId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    translatedChangedValueName?: string | null;
    /**
     * 
     * @type {BatchNumberDto}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    batchNumber?: BatchNumberDto;
    /**
     * 
     * @type {StockOperationDetailDto}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    stockOperationDetail?: StockOperationDetailDto;
    /**
     * 
     * @type {StockOperationHistoryValues}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    changedValueName?: StockOperationHistoryValues;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    stockOperationType?: StockOperationType;
    /**
     * 
     * @type {StockOperationDto}
     * @memberof StockOperationUpdateHistoryCreateDto
     */
    stockOperation?: StockOperationDto;
}
/**
 * 
 * @export
 * @interface StockOperationUpdateHistoryDetailsDto
 */
export interface StockOperationUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    stockOperationDetailId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    translatedChangedValueName?: string | null;
    /**
     * 
     * @type {StockOperationHistoryValues}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    changedValueName?: StockOperationHistoryValues;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    stockOperationType?: StockOperationType;
    /**
     * 
     * @type {StockOperationDto}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    stockOperation?: StockOperationDto;
    /**
     * 
     * @type {StockOperationDetailDto}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    stockOperationDetail?: StockOperationDetailDto;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    type?: StockOperationType;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    quantity?: number;
    /**
     * 
     * @type {UnitOfMeasureDto}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    unit?: UnitOfMeasureDto;
    /**
     * 
     * @type {BatchNumberDto}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    batchNumber?: BatchNumberDto;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    internalStockOperationWarehouseSourceId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDetailsDto
     */
    internalStockOperationWarehouseDestinationId?: number | null;
}
/**
 * 
 * @export
 * @interface StockOperationUpdateHistoryDto
 */
export interface StockOperationUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof StockOperationUpdateHistoryDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof StockOperationUpdateHistoryDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryDto
     */
    stockOperationDetailId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryDto
     */
    translatedChangedValueName?: string | null;
    /**
     * 
     * @type {BatchNumberDto}
     * @memberof StockOperationUpdateHistoryDto
     */
    batchNumber?: BatchNumberDto;
    /**
     * 
     * @type {StockOperationDetailDto}
     * @memberof StockOperationUpdateHistoryDto
     */
    stockOperationDetail?: StockOperationDetailDto;
    /**
     * 
     * @type {StockOperationHistoryValues}
     * @memberof StockOperationUpdateHistoryDto
     */
    changedValueName?: StockOperationHistoryValues;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationUpdateHistoryDto
     */
    stockOperationType?: StockOperationType;
    /**
     * 
     * @type {StockOperationDto}
     * @memberof StockOperationUpdateHistoryDto
     */
    stockOperation?: StockOperationDto;
}
/**
 * 
 * @export
 * @interface StockOperationUpdateHistoryUpdateDto
 */
export interface StockOperationUpdateHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    stockOperationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    stockOperationDetailId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    translatedChangedValueName?: string | null;
    /**
     * 
     * @type {BatchNumberDto}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    batchNumber?: BatchNumberDto;
    /**
     * 
     * @type {StockOperationDetailDto}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    stockOperationDetail?: StockOperationDetailDto;
    /**
     * 
     * @type {StockOperationHistoryValues}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    changedValueName?: StockOperationHistoryValues;
    /**
     * 
     * @type {StockOperationType}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    stockOperationType?: StockOperationType;
    /**
     * 
     * @type {StockOperationDto}
     * @memberof StockOperationUpdateHistoryUpdateDto
     */
    stockOperation?: StockOperationDto;
}
/**
 * 
 * @export
 * @interface UnitOfMeasureCreateDto
 */
export interface UnitOfMeasureCreateDto {
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureCreateDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureCreateDto
     */
    conversionToBase?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureCreateDto
     */
    baseUnitId?: number | null;
    /**
     * 
     * @type {SimpleBaseUnitDto}
     * @memberof UnitOfMeasureCreateDto
     */
    baseUnit?: SimpleBaseUnitDto;
    /**
     * 
     * @type {Array<UnitOfMeasureDto>}
     * @memberof UnitOfMeasureCreateDto
     */
    auxiliaryUnits?: Array<UnitOfMeasureDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitOfMeasureCreateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface UnitOfMeasureDetailsDto
 */
export interface UnitOfMeasureDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDetailsDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDetailsDto
     */
    conversionToBase?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDetailsDto
     */
    baseUnitId?: number | null;
    /**
     * 
     * @type {SimpleBaseUnitDto}
     * @memberof UnitOfMeasureDetailsDto
     */
    baseUnit?: SimpleBaseUnitDto;
    /**
     * 
     * @type {Array<UnitOfMeasureDto>}
     * @memberof UnitOfMeasureDetailsDto
     */
    auxiliaryUnits?: Array<UnitOfMeasureDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitOfMeasureDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof UnitOfMeasureDetailsDto
     */
    baseUnitProducts?: Array<ProductDto> | null;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof UnitOfMeasureDetailsDto
     */
    auxilaryUnitsProducts?: Array<ProductDto> | null;
}
/**
 * 
 * @export
 * @interface UnitOfMeasureDto
 */
export interface UnitOfMeasureDto {
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDto
     */
    conversionToBase?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureDto
     */
    baseUnitId?: number | null;
    /**
     * 
     * @type {SimpleBaseUnitDto}
     * @memberof UnitOfMeasureDto
     */
    baseUnit?: SimpleBaseUnitDto;
    /**
     * 
     * @type {Array<UnitOfMeasureDto>}
     * @memberof UnitOfMeasureDto
     */
    auxiliaryUnits?: Array<UnitOfMeasureDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitOfMeasureDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface UnitOfMeasureUpdateDto
 */
export interface UnitOfMeasureUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureUpdateDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasureUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureUpdateDto
     */
    conversionToBase?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasureUpdateDto
     */
    baseUnitId?: number | null;
    /**
     * 
     * @type {SimpleBaseUnitDto}
     * @memberof UnitOfMeasureUpdateDto
     */
    baseUnit?: SimpleBaseUnitDto;
    /**
     * 
     * @type {Array<UnitOfMeasureDto>}
     * @memberof UnitOfMeasureUpdateDto
     */
    auxiliaryUnits?: Array<UnitOfMeasureDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitOfMeasureUpdateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface WarehouseCreateDto
 */
export interface WarehouseCreateDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseCreateDto
     */
    parentWarehouseId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseCreateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface WarehouseDetailsDto
 */
export interface WarehouseDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDetailsDto
     */
    parentWarehouseId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {WarehouseDto}
     * @memberof WarehouseDetailsDto
     */
    parentWarehouse?: WarehouseDto;
    /**
     * 
     * @type {Array<WarehouseDto>}
     * @memberof WarehouseDetailsDto
     */
    warehouses?: Array<WarehouseDto> | null;
}
/**
 * 
 * @export
 * @interface WarehouseDto
 */
export interface WarehouseDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDto
     */
    parentWarehouseId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface WarehouseUpdateDto
 */
export interface WarehouseUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateDto
     */
    parentWarehouseId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseUpdateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface WarehouseUpdateHistoryCreateDto
 */
export interface WarehouseUpdateHistoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryCreateDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface WarehouseUpdateHistoryDetailsDto
 */
export interface WarehouseUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDetailsDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface WarehouseUpdateHistoryDto
 */
export interface WarehouseUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof WarehouseUpdateHistoryDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof WarehouseUpdateHistoryDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryDto
     */
    translatedChangedValueName?: string | null;
}
/**
 * 
 * @export
 * @interface WarehouseUpdateHistoryUpdateDto
 */
export interface WarehouseUpdateHistoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {OperationType}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    operationType?: OperationType;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {number}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    warehouseId?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseUpdateHistoryUpdateDto
     */
    translatedChangedValueName?: string | null;
}

/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [auth0UserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDelete: async (auth0UserId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (auth0UserId !== undefined) {
                localVarQueryParameter['auth0UserId'] = auth0UserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserInviteUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/inviteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPut: async (userId?: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUserDetailsGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/userDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUserDetailsMinimalGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/userDetailsMinimal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUsersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [auth0UserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserDelete(auth0UserId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserDelete(auth0UserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserInviteUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserRegisterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserInviteUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPut(userId?: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPut(userId, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUserDetailsGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserRegisterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUserDetailsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUserDetailsMinimalGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserBaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUserDetailsMinimalGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUsersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [auth0UserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDelete(auth0UserId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserDelete(auth0UserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserInviteUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserRegisterDto> {
            return localVarFp.apiApplicationUserInviteUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPut(userId?: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPut(userId, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUserDetailsGet(userId?: number, options?: any): AxiosPromise<ApplicationUserRegisterDto> {
            return localVarFp.apiApplicationUserUserDetailsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUserDetailsMinimalGet(userId?: number, options?: any): AxiosPromise<ApplicationUserBaseDto> {
            return localVarFp.apiApplicationUserUserDetailsMinimalGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUsersGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserUsersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {string} [auth0UserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserDelete(auth0UserId?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserDelete(auth0UserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserInviteUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserInviteUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPut(userId?: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPut(userId, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserUserDetailsGet(userId?: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserUserDetailsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserUserDetailsMinimalGet(userId?: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserUserDetailsMinimalGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserUsersGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserUsersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationUserUpdateHistoryApi - axios parameter creator
 * @export
 */
export const ApplicationUserUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUserUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryHistoryUserIdGet: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiApplicationUserUpdateHistoryHistoryUserIdGet', 'userId', userId)
            const localVarPath = `/api/ApplicationUserUpdateHistory/history/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserUpdateHistoryIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUserUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserUpdateHistoryIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUserUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserUpdateHistoryUpdateDto} [applicationUserUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryIdPut: async (id: number, applicationUserUpdateHistoryUpdateDto?: ApplicationUserUpdateHistoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserUpdateHistoryIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUserUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserUpdateHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserUpdateHistoryCreateDto} [applicationUserUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryPost: async (applicationUserUpdateHistoryCreateDto?: ApplicationUserUpdateHistoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUserUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserUpdateHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserUpdateHistoryApi - functional programming interface
 * @export
 */
export const ApplicationUserUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateHistoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateHistoryHistoryUserIdGet(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateHistoryHistoryUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateHistoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserUpdateHistoryUpdateDto} [applicationUserUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateHistoryIdPut(id: number, applicationUserUpdateHistoryUpdateDto?: ApplicationUserUpdateHistoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateHistoryIdPut(id, applicationUserUpdateHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserUpdateHistoryCreateDto} [applicationUserUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateHistoryPost(applicationUserUpdateHistoryCreateDto?: ApplicationUserUpdateHistoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateHistoryPost(applicationUserUpdateHistoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserUpdateHistoryApi - factory interface
 * @export
 */
export const ApplicationUserUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryGet(options?: any): AxiosPromise<Array<ApplicationUserUpdateHistoryDto>> {
            return localVarFp.apiApplicationUserUpdateHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryHistoryUserIdGet(userId: number, options?: any): AxiosPromise<Array<ApplicationUserUpdateHistoryDto>> {
            return localVarFp.apiApplicationUserUpdateHistoryHistoryUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserUpdateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryIdGet(id: number, options?: any): AxiosPromise<ApplicationUserUpdateHistoryDetailsDto> {
            return localVarFp.apiApplicationUserUpdateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserUpdateHistoryUpdateDto} [applicationUserUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryIdPut(id: number, applicationUserUpdateHistoryUpdateDto?: ApplicationUserUpdateHistoryUpdateDto, options?: any): AxiosPromise<ApplicationUserUpdateHistoryDetailsDto> {
            return localVarFp.apiApplicationUserUpdateHistoryIdPut(id, applicationUserUpdateHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserUpdateHistoryCreateDto} [applicationUserUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateHistoryPost(applicationUserUpdateHistoryCreateDto?: ApplicationUserUpdateHistoryCreateDto, options?: any): AxiosPromise<ApplicationUserUpdateHistoryDetailsDto> {
            return localVarFp.apiApplicationUserUpdateHistoryPost(applicationUserUpdateHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserUpdateHistoryApi - object-oriented interface
 * @export
 * @class ApplicationUserUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class ApplicationUserUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserUpdateHistoryApi
     */
    public apiApplicationUserUpdateHistoryGet(options?: any) {
        return ApplicationUserUpdateHistoryApiFp(this.configuration).apiApplicationUserUpdateHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserUpdateHistoryApi
     */
    public apiApplicationUserUpdateHistoryHistoryUserIdGet(userId: number, options?: any) {
        return ApplicationUserUpdateHistoryApiFp(this.configuration).apiApplicationUserUpdateHistoryHistoryUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserUpdateHistoryApi
     */
    public apiApplicationUserUpdateHistoryIdDelete(id: number, options?: any) {
        return ApplicationUserUpdateHistoryApiFp(this.configuration).apiApplicationUserUpdateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserUpdateHistoryApi
     */
    public apiApplicationUserUpdateHistoryIdGet(id: number, options?: any) {
        return ApplicationUserUpdateHistoryApiFp(this.configuration).apiApplicationUserUpdateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserUpdateHistoryUpdateDto} [applicationUserUpdateHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserUpdateHistoryApi
     */
    public apiApplicationUserUpdateHistoryIdPut(id: number, applicationUserUpdateHistoryUpdateDto?: ApplicationUserUpdateHistoryUpdateDto, options?: any) {
        return ApplicationUserUpdateHistoryApiFp(this.configuration).apiApplicationUserUpdateHistoryIdPut(id, applicationUserUpdateHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserUpdateHistoryCreateDto} [applicationUserUpdateHistoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserUpdateHistoryApi
     */
    public apiApplicationUserUpdateHistoryPost(applicationUserUpdateHistoryCreateDto?: ApplicationUserUpdateHistoryCreateDto, options?: any) {
        return ApplicationUserUpdateHistoryApiFp(this.configuration).apiApplicationUserUpdateHistoryPost(applicationUserUpdateHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyDataApi - axios parameter creator
 * @export
 */
export const CompanyDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompanyDataIdDelete', 'id', id)
            const localVarPath = `/api/CompanyData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompanyDataIdGet', 'id', id)
            const localVarPath = `/api/CompanyData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDataUpdateDto} [companyDataUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataIdPut: async (id: number, companyDataUpdateDto?: CompanyDataUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompanyDataIdPut', 'id', id)
            const localVarPath = `/api/CompanyData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDataUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataMinimalGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyData/minimal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDataCreateDto} [companyDataCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataPost: async (companyDataCreateDto?: CompanyDataCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDataCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyDataApi - functional programming interface
 * @export
 */
export const CompanyDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDataUpdateDto} [companyDataUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataIdPut(id: number, companyDataUpdateDto?: CompanyDataUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataIdPut(id, companyDataUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataMinimalGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataMinimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataMinimalGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyDataCreateDto} [companyDataCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataPost(companyDataCreateDto?: CompanyDataCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataPost(companyDataCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyDataApi - factory interface
 * @export
 */
export const CompanyDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyDataApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataGet(options?: any): AxiosPromise<Array<CompanyDataDto>> {
            return localVarFp.apiCompanyDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataIdGet(id: number, options?: any): AxiosPromise<CompanyDataDetailsDto> {
            return localVarFp.apiCompanyDataIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDataUpdateDto} [companyDataUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataIdPut(id: number, companyDataUpdateDto?: CompanyDataUpdateDto, options?: any): AxiosPromise<CompanyDataDetailsDto> {
            return localVarFp.apiCompanyDataIdPut(id, companyDataUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataMinimalGet(options?: any): AxiosPromise<CompanyDataMinimalDto> {
            return localVarFp.apiCompanyDataMinimalGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyDataCreateDto} [companyDataCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataPost(companyDataCreateDto?: CompanyDataCreateDto, options?: any): AxiosPromise<CompanyDataDetailsDto> {
            return localVarFp.apiCompanyDataPost(companyDataCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyDataApi - object-oriented interface
 * @export
 * @class CompanyDataApi
 * @extends {BaseAPI}
 */
export class CompanyDataApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataApi
     */
    public apiCompanyDataGet(options?: any) {
        return CompanyDataApiFp(this.configuration).apiCompanyDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataApi
     */
    public apiCompanyDataIdDelete(id: number, options?: any) {
        return CompanyDataApiFp(this.configuration).apiCompanyDataIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataApi
     */
    public apiCompanyDataIdGet(id: number, options?: any) {
        return CompanyDataApiFp(this.configuration).apiCompanyDataIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompanyDataUpdateDto} [companyDataUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataApi
     */
    public apiCompanyDataIdPut(id: number, companyDataUpdateDto?: CompanyDataUpdateDto, options?: any) {
        return CompanyDataApiFp(this.configuration).apiCompanyDataIdPut(id, companyDataUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataApi
     */
    public apiCompanyDataMinimalGet(options?: any) {
        return CompanyDataApiFp(this.configuration).apiCompanyDataMinimalGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyDataCreateDto} [companyDataCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataApi
     */
    public apiCompanyDataPost(companyDataCreateDto?: CompanyDataCreateDto, options?: any) {
        return CompanyDataApiFp(this.configuration).apiCompanyDataPost(companyDataCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyDataUpdateHistoryApi - axios parameter creator
 * @export
 */
export const CompanyDataUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompanyDataUpdateHistoryIdDelete', 'id', id)
            const localVarPath = `/api/CompanyDataUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompanyDataUpdateHistoryIdGet', 'id', id)
            const localVarPath = `/api/CompanyDataUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDataUpdateHistoryUpdateDto} [companyDataUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryIdPut: async (id: number, companyDataUpdateHistoryUpdateDto?: CompanyDataUpdateHistoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompanyDataUpdateHistoryIdPut', 'id', id)
            const localVarPath = `/api/CompanyDataUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDataUpdateHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDataUpdateHistoryCreateDto} [companyDataUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryPost: async (companyDataUpdateHistoryCreateDto?: CompanyDataUpdateHistoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDataUpdateHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyDataUpdateHistoryApi - functional programming interface
 * @export
 */
export const CompanyDataUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyDataUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataUpdateHistoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDataUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataUpdateHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataUpdateHistoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataUpdateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataUpdateHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataUpdateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDataUpdateHistoryUpdateDto} [companyDataUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataUpdateHistoryIdPut(id: number, companyDataUpdateHistoryUpdateDto?: CompanyDataUpdateHistoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataUpdateHistoryIdPut(id, companyDataUpdateHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyDataUpdateHistoryCreateDto} [companyDataUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataUpdateHistoryPost(companyDataUpdateHistoryCreateDto?: CompanyDataUpdateHistoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataUpdateHistoryPost(companyDataUpdateHistoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyDataUpdateHistoryApi - factory interface
 * @export
 */
export const CompanyDataUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyDataUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryGet(options?: any): AxiosPromise<Array<CompanyDataUpdateHistoryDto>> {
            return localVarFp.apiCompanyDataUpdateHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataUpdateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryIdGet(id: number, options?: any): AxiosPromise<CompanyDataUpdateHistoryDetailsDto> {
            return localVarFp.apiCompanyDataUpdateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDataUpdateHistoryUpdateDto} [companyDataUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryIdPut(id: number, companyDataUpdateHistoryUpdateDto?: CompanyDataUpdateHistoryUpdateDto, options?: any): AxiosPromise<CompanyDataUpdateHistoryDetailsDto> {
            return localVarFp.apiCompanyDataUpdateHistoryIdPut(id, companyDataUpdateHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyDataUpdateHistoryCreateDto} [companyDataUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataUpdateHistoryPost(companyDataUpdateHistoryCreateDto?: CompanyDataUpdateHistoryCreateDto, options?: any): AxiosPromise<CompanyDataUpdateHistoryDetailsDto> {
            return localVarFp.apiCompanyDataUpdateHistoryPost(companyDataUpdateHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyDataUpdateHistoryApi - object-oriented interface
 * @export
 * @class CompanyDataUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class CompanyDataUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataUpdateHistoryApi
     */
    public apiCompanyDataUpdateHistoryGet(options?: any) {
        return CompanyDataUpdateHistoryApiFp(this.configuration).apiCompanyDataUpdateHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataUpdateHistoryApi
     */
    public apiCompanyDataUpdateHistoryIdDelete(id: number, options?: any) {
        return CompanyDataUpdateHistoryApiFp(this.configuration).apiCompanyDataUpdateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataUpdateHistoryApi
     */
    public apiCompanyDataUpdateHistoryIdGet(id: number, options?: any) {
        return CompanyDataUpdateHistoryApiFp(this.configuration).apiCompanyDataUpdateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompanyDataUpdateHistoryUpdateDto} [companyDataUpdateHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataUpdateHistoryApi
     */
    public apiCompanyDataUpdateHistoryIdPut(id: number, companyDataUpdateHistoryUpdateDto?: CompanyDataUpdateHistoryUpdateDto, options?: any) {
        return CompanyDataUpdateHistoryApiFp(this.configuration).apiCompanyDataUpdateHistoryIdPut(id, companyDataUpdateHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyDataUpdateHistoryCreateDto} [companyDataUpdateHistoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataUpdateHistoryApi
     */
    public apiCompanyDataUpdateHistoryPost(companyDataUpdateHistoryCreateDto?: CompanyDataUpdateHistoryCreateDto, options?: any) {
        return CompanyDataUpdateHistoryApiFp(this.configuration).apiCompanyDataUpdateHistoryPost(companyDataUpdateHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractorApi - axios parameter creator
 * @export
 */
export const ContractorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contractor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorIdDelete', 'id', id)
            const localVarPath = `/api/Contractor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorIdGet', 'id', id)
            const localVarPath = `/api/Contractor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContractorUpdateDto} [contractorUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorIdPut: async (id: number, contractorUpdateDto?: ContractorUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorIdPut', 'id', id)
            const localVarPath = `/api/Contractor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractorUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractorCreateDto} [contractorCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorPost: async (contractorCreateDto?: ContractorCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contractor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractorCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractorApi - functional programming interface
 * @export
 */
export const ContractorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractorDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ContractorUpdateDto} [contractorUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorIdPut(id: number, contractorUpdateDto?: ContractorUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorIdPut(id, contractorUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractorCreateDto} [contractorCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorPost(contractorCreateDto?: ContractorCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorPost(contractorCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractorApi - factory interface
 * @export
 */
export const ContractorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorGet(options?: any): AxiosPromise<Array<ContractorDto>> {
            return localVarFp.apiContractorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiContractorIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorIdGet(id: number, options?: any): AxiosPromise<ContractorDetailsDto> {
            return localVarFp.apiContractorIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ContractorUpdateDto} [contractorUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorIdPut(id: number, contractorUpdateDto?: ContractorUpdateDto, options?: any): AxiosPromise<ContractorDetailsDto> {
            return localVarFp.apiContractorIdPut(id, contractorUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractorCreateDto} [contractorCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorPost(contractorCreateDto?: ContractorCreateDto, options?: any): AxiosPromise<ContractorDetailsDto> {
            return localVarFp.apiContractorPost(contractorCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractorApi - object-oriented interface
 * @export
 * @class ContractorApi
 * @extends {BaseAPI}
 */
export class ContractorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiContractorGet(options?: any) {
        return ContractorApiFp(this.configuration).apiContractorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiContractorIdDelete(id: number, options?: any) {
        return ContractorApiFp(this.configuration).apiContractorIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiContractorIdGet(id: number, options?: any) {
        return ContractorApiFp(this.configuration).apiContractorIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ContractorUpdateDto} [contractorUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiContractorIdPut(id: number, contractorUpdateDto?: ContractorUpdateDto, options?: any) {
        return ContractorApiFp(this.configuration).apiContractorIdPut(id, contractorUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractorCreateDto} [contractorCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiContractorPost(contractorCreateDto?: ContractorCreateDto, options?: any) {
        return ContractorApiFp(this.configuration).apiContractorPost(contractorCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractorUpdateHistoryApi - axios parameter creator
 * @export
 */
export const ContractorUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContractorUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorUpdateHistoryIdDelete', 'id', id)
            const localVarPath = `/api/ContractorUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorUpdateHistoryIdGet', 'id', id)
            const localVarPath = `/api/ContractorUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdHistoryGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorUpdateHistoryIdHistoryGet', 'id', id)
            const localVarPath = `/api/ContractorUpdateHistory/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ContractorUpdateHistoryUpdateDto} [contractorUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdPut: async (id: number, contractorUpdateHistoryUpdateDto?: ContractorUpdateHistoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContractorUpdateHistoryIdPut', 'id', id)
            const localVarPath = `/api/ContractorUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractorUpdateHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContractorUpdateHistoryCreateDto} [contractorUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryPost: async (contractorUpdateHistoryCreateDto?: ContractorUpdateHistoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContractorUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractorUpdateHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractorUpdateHistoryApi - functional programming interface
 * @export
 */
export const ContractorUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractorUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorUpdateHistoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractorUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorUpdateHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorUpdateHistoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorUpdateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorUpdateHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorUpdateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorUpdateHistoryIdHistoryGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractorUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorUpdateHistoryIdHistoryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ContractorUpdateHistoryUpdateDto} [contractorUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorUpdateHistoryIdPut(id: number, contractorUpdateHistoryUpdateDto?: ContractorUpdateHistoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorUpdateHistoryIdPut(id, contractorUpdateHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContractorUpdateHistoryCreateDto} [contractorUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContractorUpdateHistoryPost(contractorUpdateHistoryCreateDto?: ContractorUpdateHistoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContractorUpdateHistoryPost(contractorUpdateHistoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractorUpdateHistoryApi - factory interface
 * @export
 */
export const ContractorUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractorUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryGet(options?: any): AxiosPromise<Array<ContractorUpdateHistoryDto>> {
            return localVarFp.apiContractorUpdateHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiContractorUpdateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdGet(id: number, options?: any): AxiosPromise<ContractorUpdateHistoryDetailsDto> {
            return localVarFp.apiContractorUpdateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdHistoryGet(id: number, options?: any): AxiosPromise<Array<ContractorUpdateHistoryDto>> {
            return localVarFp.apiContractorUpdateHistoryIdHistoryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ContractorUpdateHistoryUpdateDto} [contractorUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryIdPut(id: number, contractorUpdateHistoryUpdateDto?: ContractorUpdateHistoryUpdateDto, options?: any): AxiosPromise<ContractorUpdateHistoryDetailsDto> {
            return localVarFp.apiContractorUpdateHistoryIdPut(id, contractorUpdateHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractorUpdateHistoryCreateDto} [contractorUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContractorUpdateHistoryPost(contractorUpdateHistoryCreateDto?: ContractorUpdateHistoryCreateDto, options?: any): AxiosPromise<ContractorUpdateHistoryDetailsDto> {
            return localVarFp.apiContractorUpdateHistoryPost(contractorUpdateHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractorUpdateHistoryApi - object-oriented interface
 * @export
 * @class ContractorUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class ContractorUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorUpdateHistoryApi
     */
    public apiContractorUpdateHistoryGet(options?: any) {
        return ContractorUpdateHistoryApiFp(this.configuration).apiContractorUpdateHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorUpdateHistoryApi
     */
    public apiContractorUpdateHistoryIdDelete(id: number, options?: any) {
        return ContractorUpdateHistoryApiFp(this.configuration).apiContractorUpdateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorUpdateHistoryApi
     */
    public apiContractorUpdateHistoryIdGet(id: number, options?: any) {
        return ContractorUpdateHistoryApiFp(this.configuration).apiContractorUpdateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorUpdateHistoryApi
     */
    public apiContractorUpdateHistoryIdHistoryGet(id: number, options?: any) {
        return ContractorUpdateHistoryApiFp(this.configuration).apiContractorUpdateHistoryIdHistoryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ContractorUpdateHistoryUpdateDto} [contractorUpdateHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorUpdateHistoryApi
     */
    public apiContractorUpdateHistoryIdPut(id: number, contractorUpdateHistoryUpdateDto?: ContractorUpdateHistoryUpdateDto, options?: any) {
        return ContractorUpdateHistoryApiFp(this.configuration).apiContractorUpdateHistoryIdPut(id, contractorUpdateHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractorUpdateHistoryCreateDto} [contractorUpdateHistoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorUpdateHistoryApi
     */
    public apiContractorUpdateHistoryPost(contractorUpdateHistoryCreateDto?: ContractorUpdateHistoryCreateDto, options?: any) {
        return ContractorUpdateHistoryApiFp(this.configuration).apiContractorUpdateHistoryPost(contractorUpdateHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CropApi - axios parameter creator
 * @export
 */
export const CropApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Crop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCropIdDelete', 'id', id)
            const localVarPath = `/api/Crop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCropIdGet', 'id', id)
            const localVarPath = `/api/Crop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CropUpdateDto} [cropUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropIdPut: async (id: number, cropUpdateDto?: CropUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCropIdPut', 'id', id)
            const localVarPath = `/api/Crop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cropUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CropCreateDto} [cropCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropPost: async (cropCreateDto?: CropCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Crop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cropCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CropApi - functional programming interface
 * @export
 */
export const CropApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CropApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCropGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CropDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCropGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCropIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCropIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCropIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CropDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCropIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CropUpdateDto} [cropUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCropIdPut(id: number, cropUpdateDto?: CropUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CropDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCropIdPut(id, cropUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CropCreateDto} [cropCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCropPost(cropCreateDto?: CropCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CropDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCropPost(cropCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CropApi - factory interface
 * @export
 */
export const CropApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CropApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropGet(options?: any): AxiosPromise<Array<CropDto>> {
            return localVarFp.apiCropGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCropIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropIdGet(id: number, options?: any): AxiosPromise<CropDetailsDto> {
            return localVarFp.apiCropIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CropUpdateDto} [cropUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropIdPut(id: number, cropUpdateDto?: CropUpdateDto, options?: any): AxiosPromise<CropDetailsDto> {
            return localVarFp.apiCropIdPut(id, cropUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CropCreateDto} [cropCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCropPost(cropCreateDto?: CropCreateDto, options?: any): AxiosPromise<CropDetailsDto> {
            return localVarFp.apiCropPost(cropCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CropApi - object-oriented interface
 * @export
 * @class CropApi
 * @extends {BaseAPI}
 */
export class CropApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CropApi
     */
    public apiCropGet(options?: any) {
        return CropApiFp(this.configuration).apiCropGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CropApi
     */
    public apiCropIdDelete(id: number, options?: any) {
        return CropApiFp(this.configuration).apiCropIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CropApi
     */
    public apiCropIdGet(id: number, options?: any) {
        return CropApiFp(this.configuration).apiCropIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CropUpdateDto} [cropUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CropApi
     */
    public apiCropIdPut(id: number, cropUpdateDto?: CropUpdateDto, options?: any) {
        return CropApiFp(this.configuration).apiCropIdPut(id, cropUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CropCreateDto} [cropCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CropApi
     */
    public apiCropPost(cropCreateDto?: CropCreateDto, options?: any) {
        return CropApiFp(this.configuration).apiCropPost(cropCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryOperationApi - axios parameter creator
 * @export
 */
export const InventoryOperationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInventoryOperationIdDelete', 'id', id)
            const localVarPath = `/api/InventoryOperation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInventoryOperationIdGet', 'id', id)
            const localVarPath = `/api/InventoryOperation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InventoryOperationUpdateDto} [inventoryOperationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdPut: async (id: number, inventoryOperationUpdateDto?: InventoryOperationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInventoryOperationIdPut', 'id', id)
            const localVarPath = `/api/InventoryOperation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryOperationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdStatusPut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInventoryOperationIdStatusPut', 'id', id)
            const localVarPath = `/api/InventoryOperation/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InventoryOperationCreateDto} [inventoryOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationPost: async (inventoryOperationCreateDto?: InventoryOperationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryOperationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryOperationApi - functional programming interface
 * @export
 */
export const InventoryOperationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryOperationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryOperationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InventoryOperationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryOperationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryOperationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryOperationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryOperationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryOperationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InventoryOperationUpdateDto} [inventoryOperationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryOperationIdPut(id: number, inventoryOperationUpdateDto?: InventoryOperationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryOperationIdPut(id, inventoryOperationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryOperationIdStatusPut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryOperationIdStatusPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InventoryOperationCreateDto} [inventoryOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryOperationPost(inventoryOperationCreateDto?: InventoryOperationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryOperationPost(inventoryOperationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryOperationApi - factory interface
 * @export
 */
export const InventoryOperationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryOperationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationGet(options?: any): AxiosPromise<Array<InventoryOperationDto>> {
            return localVarFp.apiInventoryOperationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryOperationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdGet(id: number, options?: any): AxiosPromise<InventoryOperationDetailsDto> {
            return localVarFp.apiInventoryOperationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InventoryOperationUpdateDto} [inventoryOperationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdPut(id: number, inventoryOperationUpdateDto?: InventoryOperationUpdateDto, options?: any): AxiosPromise<InventoryOperationDetailsDto> {
            return localVarFp.apiInventoryOperationIdPut(id, inventoryOperationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationIdStatusPut(id: number, options?: any): AxiosPromise<InventoryOperationDetailsDto> {
            return localVarFp.apiInventoryOperationIdStatusPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InventoryOperationCreateDto} [inventoryOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryOperationPost(inventoryOperationCreateDto?: InventoryOperationCreateDto, options?: any): AxiosPromise<InventoryOperationDetailsDto> {
            return localVarFp.apiInventoryOperationPost(inventoryOperationCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryOperationApi - object-oriented interface
 * @export
 * @class InventoryOperationApi
 * @extends {BaseAPI}
 */
export class InventoryOperationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryOperationApi
     */
    public apiInventoryOperationGet(options?: any) {
        return InventoryOperationApiFp(this.configuration).apiInventoryOperationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryOperationApi
     */
    public apiInventoryOperationIdDelete(id: number, options?: any) {
        return InventoryOperationApiFp(this.configuration).apiInventoryOperationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryOperationApi
     */
    public apiInventoryOperationIdGet(id: number, options?: any) {
        return InventoryOperationApiFp(this.configuration).apiInventoryOperationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InventoryOperationUpdateDto} [inventoryOperationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryOperationApi
     */
    public apiInventoryOperationIdPut(id: number, inventoryOperationUpdateDto?: InventoryOperationUpdateDto, options?: any) {
        return InventoryOperationApiFp(this.configuration).apiInventoryOperationIdPut(id, inventoryOperationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryOperationApi
     */
    public apiInventoryOperationIdStatusPut(id: number, options?: any) {
        return InventoryOperationApiFp(this.configuration).apiInventoryOperationIdStatusPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InventoryOperationCreateDto} [inventoryOperationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryOperationApi
     */
    public apiInventoryOperationPost(inventoryOperationCreateDto?: InventoryOperationCreateDto, options?: any) {
        return InventoryOperationApiFp(this.configuration).apiInventoryOperationPost(inventoryOperationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdDelete', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdGet', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdMinimalGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdMinimalGet', 'id', id)
            const localVarPath = `/api/Product/{id}/minimal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut: async (id: number, productUpdateDto?: ProductUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPut', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost: async (productCreateDto?: ProductCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsWarehouseWarehouseIdGet: async (warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('productsWarehouseWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/products/warehouse/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdMinimalGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductMinimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdMinimalGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPut(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPost(productCreateDto?: ProductCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPost(productCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsWarehouseWarehouseIdGet(warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsWarehouseWarehouseIdGet(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet(options?: any): AxiosPromise<Array<ProductDto>> {
            return localVarFp.apiProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet(id: number, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdMinimalGet(id: number, options?: any): AxiosPromise<ProductMinimalDto> {
            return localVarFp.apiProductIdMinimalGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPut(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost(productCreateDto?: ProductCreateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductPost(productCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsWarehouseWarehouseIdGet(warehouseId: number, options?: any): AxiosPromise<Array<ProductDto>> {
            return localVarFp.productsWarehouseWarehouseIdGet(warehouseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductGet(options?: any) {
        return ProductApiFp(this.configuration).apiProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdDelete(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdGet(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdMinimalGet(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdMinimalGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdPut(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCreateDto} [productCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductPost(productCreateDto?: ProductCreateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductPost(productCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productsWarehouseWarehouseIdGet(warehouseId: number, options?: any) {
        return ProductApiFp(this.configuration).productsWarehouseWarehouseIdGet(warehouseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductUpdateHistoryApi - axios parameter creator
 * @export
 */
export const ProductUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryHistoryProductIdGet: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductUpdateHistoryHistoryProductIdGet', 'productId', productId)
            const localVarPath = `/api/ProductUpdateHistory/history/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductUpdateHistoryIdDelete', 'id', id)
            const localVarPath = `/api/ProductUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductUpdateHistoryIdGet', 'id', id)
            const localVarPath = `/api/ProductUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateHistoryUpdateDto} [productUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryIdPut: async (id: number, productUpdateHistoryUpdateDto?: ProductUpdateHistoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductUpdateHistoryIdPut', 'id', id)
            const localVarPath = `/api/ProductUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductUpdateHistoryCreateDto} [productUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryPost: async (productUpdateHistoryCreateDto?: ProductUpdateHistoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductUpdateHistoryApi - functional programming interface
 * @export
 */
export const ProductUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductUpdateHistoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductUpdateHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductUpdateHistoryHistoryProductIdGet(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductUpdateHistoryHistoryProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductUpdateHistoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductUpdateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductUpdateHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductUpdateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateHistoryUpdateDto} [productUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductUpdateHistoryIdPut(id: number, productUpdateHistoryUpdateDto?: ProductUpdateHistoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductUpdateHistoryIdPut(id, productUpdateHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductUpdateHistoryCreateDto} [productUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductUpdateHistoryPost(productUpdateHistoryCreateDto?: ProductUpdateHistoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductUpdateHistoryPost(productUpdateHistoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductUpdateHistoryApi - factory interface
 * @export
 */
export const ProductUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryGet(options?: any): AxiosPromise<Array<ProductUpdateHistoryDto>> {
            return localVarFp.apiProductUpdateHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryHistoryProductIdGet(productId: number, options?: any): AxiosPromise<Array<ProductUpdateHistoryDto>> {
            return localVarFp.apiProductUpdateHistoryHistoryProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductUpdateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryIdGet(id: number, options?: any): AxiosPromise<ProductUpdateHistoryDetailsDto> {
            return localVarFp.apiProductUpdateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateHistoryUpdateDto} [productUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryIdPut(id: number, productUpdateHistoryUpdateDto?: ProductUpdateHistoryUpdateDto, options?: any): AxiosPromise<ProductUpdateHistoryDetailsDto> {
            return localVarFp.apiProductUpdateHistoryIdPut(id, productUpdateHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductUpdateHistoryCreateDto} [productUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductUpdateHistoryPost(productUpdateHistoryCreateDto?: ProductUpdateHistoryCreateDto, options?: any): AxiosPromise<ProductUpdateHistoryDetailsDto> {
            return localVarFp.apiProductUpdateHistoryPost(productUpdateHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductUpdateHistoryApi - object-oriented interface
 * @export
 * @class ProductUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class ProductUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUpdateHistoryApi
     */
    public apiProductUpdateHistoryGet(options?: any) {
        return ProductUpdateHistoryApiFp(this.configuration).apiProductUpdateHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUpdateHistoryApi
     */
    public apiProductUpdateHistoryHistoryProductIdGet(productId: number, options?: any) {
        return ProductUpdateHistoryApiFp(this.configuration).apiProductUpdateHistoryHistoryProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUpdateHistoryApi
     */
    public apiProductUpdateHistoryIdDelete(id: number, options?: any) {
        return ProductUpdateHistoryApiFp(this.configuration).apiProductUpdateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUpdateHistoryApi
     */
    public apiProductUpdateHistoryIdGet(id: number, options?: any) {
        return ProductUpdateHistoryApiFp(this.configuration).apiProductUpdateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateHistoryUpdateDto} [productUpdateHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUpdateHistoryApi
     */
    public apiProductUpdateHistoryIdPut(id: number, productUpdateHistoryUpdateDto?: ProductUpdateHistoryUpdateDto, options?: any) {
        return ProductUpdateHistoryApiFp(this.configuration).apiProductUpdateHistoryIdPut(id, productUpdateHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductUpdateHistoryCreateDto} [productUpdateHistoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUpdateHistoryApi
     */
    public apiProductUpdateHistoryPost(productUpdateHistoryCreateDto?: ProductUpdateHistoryCreateDto, options?: any) {
        return ProductUpdateHistoryApiFp(this.configuration).apiProductUpdateHistoryPost(productUpdateHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductionProcessApi - axios parameter creator
 * @export
 */
export const ProductionProcessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductionProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductionProcessIdDelete', 'id', id)
            const localVarPath = `/api/ProductionProcess/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductionProcessIdGet', 'id', id)
            const localVarPath = `/api/ProductionProcess/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductionProcessUpdateDto} [productionProcessUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdPut: async (id: number, productionProcessUpdateDto?: ProductionProcessUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductionProcessIdPut', 'id', id)
            const localVarPath = `/api/ProductionProcess/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productionProcessUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdStatusPut: async (id: number, status?: StockOperationStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductionProcessIdStatusPut', 'id', id)
            const localVarPath = `/api/ProductionProcess/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductionProcessCreateDto} [productionProcessCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessPost: async (productionProcessCreateDto?: ProductionProcessCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductionProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productionProcessCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockOperationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessStatusStatusGet: async (status: StockOperationStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('apiProductionProcessStatusStatusGet', 'status', status)
            const localVarPath = `/api/ProductionProcess/status/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductionProcessApi - functional programming interface
 * @export
 */
export const ProductionProcessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductionProcessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionProcessDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionProcessDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductionProcessUpdateDto} [productionProcessUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessIdPut(id: number, productionProcessUpdateDto?: ProductionProcessUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionProcessDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessIdPut(id, productionProcessUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessIdStatusPut(id: number, status?: StockOperationStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionProcessDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessIdStatusPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductionProcessCreateDto} [productionProcessCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessPost(productionProcessCreateDto?: ProductionProcessCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionProcessDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessPost(productionProcessCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StockOperationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductionProcessStatusStatusGet(status: StockOperationStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionProcessDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductionProcessStatusStatusGet(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductionProcessApi - factory interface
 * @export
 */
export const ProductionProcessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductionProcessApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessGet(options?: any): AxiosPromise<Array<ProductionProcessDto>> {
            return localVarFp.apiProductionProcessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductionProcessIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdGet(id: number, options?: any): AxiosPromise<ProductionProcessDetailsDto> {
            return localVarFp.apiProductionProcessIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductionProcessUpdateDto} [productionProcessUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdPut(id: number, productionProcessUpdateDto?: ProductionProcessUpdateDto, options?: any): AxiosPromise<ProductionProcessDetailsDto> {
            return localVarFp.apiProductionProcessIdPut(id, productionProcessUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessIdStatusPut(id: number, status?: StockOperationStatus, options?: any): AxiosPromise<ProductionProcessDetailsDto> {
            return localVarFp.apiProductionProcessIdStatusPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductionProcessCreateDto} [productionProcessCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessPost(productionProcessCreateDto?: ProductionProcessCreateDto, options?: any): AxiosPromise<ProductionProcessDetailsDto> {
            return localVarFp.apiProductionProcessPost(productionProcessCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockOperationStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductionProcessStatusStatusGet(status: StockOperationStatus, options?: any): AxiosPromise<Array<ProductionProcessDto>> {
            return localVarFp.apiProductionProcessStatusStatusGet(status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductionProcessApi - object-oriented interface
 * @export
 * @class ProductionProcessApi
 * @extends {BaseAPI}
 */
export class ProductionProcessApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessGet(options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessIdDelete(id: number, options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessIdGet(id: number, options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductionProcessUpdateDto} [productionProcessUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessIdPut(id: number, productionProcessUpdateDto?: ProductionProcessUpdateDto, options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessIdPut(id, productionProcessUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StockOperationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessIdStatusPut(id: number, status?: StockOperationStatus, options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessIdStatusPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductionProcessCreateDto} [productionProcessCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessPost(productionProcessCreateDto?: ProductionProcessCreateDto, options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessPost(productionProcessCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockOperationStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionProcessApi
     */
    public apiProductionProcessStatusStatusGet(status: StockOperationStatus, options?: any) {
        return ProductionProcessApiFp(this.configuration).apiProductionProcessStatusStatusGet(status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockApi - axios parameter creator
 * @export
 */
export const StockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockGetAllGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/stock/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockProductProductIdGet: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiStockStockProductProductIdGet', 'productId', productId)
            const localVarPath = `/api/Stock/stock/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockProductProductIdWarehouseWarehouseIdGet: async (productId: number, warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiStockStockProductProductIdWarehouseWarehouseIdGet', 'productId', productId)
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiStockStockProductProductIdWarehouseWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/Stock/stock/product/{productId}/warehouse/{warehouseId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockWarehouseWarehouseIdGet: async (warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiStockStockWarehouseWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/Stock/stock/warehouse/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockWarehouseWithSubwarehousesWarehouseIdGet: async (warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiStockStockWarehouseWithSubwarehousesWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/Stock/stock/warehouseWithSubwarehouses/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockApi - functional programming interface
 * @export
 */
export const StockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockStockGetAllGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockStockGetAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockStockProductProductIdGet(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockStockProductProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockStockProductProductIdWarehouseWarehouseIdGet(productId: number, warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockStockProductProductIdWarehouseWarehouseIdGet(productId, warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockStockWarehouseWarehouseIdGet(warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockStockWarehouseWarehouseIdGet(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockStockWarehouseWithSubwarehousesWarehouseIdGet(warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockStockWarehouseWithSubwarehousesWarehouseIdGet(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockApi - factory interface
 * @export
 */
export const StockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockGetAllGet(options?: any): AxiosPromise<Array<StockDto>> {
            return localVarFp.apiStockStockGetAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockProductProductIdGet(productId: number, options?: any): AxiosPromise<Array<StockDto>> {
            return localVarFp.apiStockStockProductProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockProductProductIdWarehouseWarehouseIdGet(productId: number, warehouseId: number, options?: any): AxiosPromise<Array<StockDto>> {
            return localVarFp.apiStockStockProductProductIdWarehouseWarehouseIdGet(productId, warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockWarehouseWarehouseIdGet(warehouseId: number, options?: any): AxiosPromise<Array<StockDto>> {
            return localVarFp.apiStockStockWarehouseWarehouseIdGet(warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockStockWarehouseWithSubwarehousesWarehouseIdGet(warehouseId: number, options?: any): AxiosPromise<Array<StockDto>> {
            return localVarFp.apiStockStockWarehouseWithSubwarehousesWarehouseIdGet(warehouseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockApi - object-oriented interface
 * @export
 * @class StockApi
 * @extends {BaseAPI}
 */
export class StockApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public apiStockStockGetAllGet(options?: any) {
        return StockApiFp(this.configuration).apiStockStockGetAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public apiStockStockProductProductIdGet(productId: number, options?: any) {
        return StockApiFp(this.configuration).apiStockStockProductProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public apiStockStockProductProductIdWarehouseWarehouseIdGet(productId: number, warehouseId: number, options?: any) {
        return StockApiFp(this.configuration).apiStockStockProductProductIdWarehouseWarehouseIdGet(productId, warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public apiStockStockWarehouseWarehouseIdGet(warehouseId: number, options?: any) {
        return StockApiFp(this.configuration).apiStockStockWarehouseWarehouseIdGet(warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public apiStockStockWarehouseWithSubwarehousesWarehouseIdGet(warehouseId: number, options?: any) {
        return StockApiFp(this.configuration).apiStockStockWarehouseWithSubwarehousesWarehouseIdGet(warehouseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockOperationApi - axios parameter creator
 * @export
 */
export const StockOperationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [productId] 
         * @param {number} [warehouseId] 
         * @param {number} [stockOperationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationBatchNumbersGet: async (productId?: number, warehouseId?: number, stockOperationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/batchNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouseId'] = warehouseId;
            }

            if (stockOperationId !== undefined) {
                localVarQueryParameter['stockOperationId'] = stockOperationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {StockOperationType} [operationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationDocumentNumberGet: async (date?: string, operationType?: StockOperationType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/documentNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (operationType !== undefined) {
                localVarQueryParameter['operationType'] = operationType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationIdDelete', 'id', id)
            const localVarPath = `/api/StockOperation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationIdGet', 'id', id)
            const localVarPath = `/api/StockOperation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationUpdateDto} [stockOperationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdPut: async (id: number, stockOperationUpdateDto?: StockOperationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationIdPut', 'id', id)
            const localVarPath = `/api/StockOperation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockOperationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdStatusPut: async (id: number, status?: StockOperationStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationIdStatusPut', 'id', id)
            const localVarPath = `/api/StockOperation/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationLatestDraftGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/latestDraft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationLatestPublishedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/latestPublished`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationMinimalIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationMinimalIdGet', 'id', id)
            const localVarPath = `/api/StockOperation/minimal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationOperationsForDateGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/operationsForDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockOperationCreateDto} [stockOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationPost: async (stockOperationCreateDto?: StockOperationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockOperationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockOperationCreateDto} [stockOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationProductionStockOperationPost: async (stockOperationCreateDto?: StockOperationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/ProductionStockOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockOperationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [documentNumber] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationValidateDocumentNumberGet: async (documentNumber?: string, id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperation/validateDocumentNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (documentNumber !== undefined) {
                localVarQueryParameter['documentNumber'] = documentNumber;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockOperationApi - functional programming interface
 * @export
 */
export const StockOperationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockOperationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [productId] 
         * @param {number} [warehouseId] 
         * @param {number} [stockOperationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationBatchNumbersGet(productId?: number, warehouseId?: number, stockOperationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchNumberDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationBatchNumbersGet(productId, warehouseId, stockOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {StockOperationType} [operationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationDocumentNumberGet(date?: string, operationType?: StockOperationType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationDocumentNumberGet(date, operationType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationUpdateDto} [stockOperationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationIdPut(id: number, stockOperationUpdateDto?: StockOperationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationIdPut(id, stockOperationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationIdStatusPut(id: number, status?: StockOperationStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationIdStatusPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationLatestDraftGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationLatestDraftGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationLatestPublishedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationLatestPublishedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationMinimalIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationMinimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationMinimalIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationOperationsForDateGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationOperationsForDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StockOperationCreateDto} [stockOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationPost(stockOperationCreateDto?: StockOperationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationPost(stockOperationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StockOperationCreateDto} [stockOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationProductionStockOperationPost(stockOperationCreateDto?: StockOperationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationProductionStockOperationPost(stockOperationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [documentNumber] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationValidateDocumentNumberGet(documentNumber?: string, id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationValidateDocumentNumberGet(documentNumber, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockOperationApi - factory interface
 * @export
 */
export const StockOperationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockOperationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [productId] 
         * @param {number} [warehouseId] 
         * @param {number} [stockOperationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationBatchNumbersGet(productId?: number, warehouseId?: number, stockOperationId?: number, options?: any): AxiosPromise<Array<BatchNumberDto>> {
            return localVarFp.apiStockOperationBatchNumbersGet(productId, warehouseId, stockOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {StockOperationType} [operationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationDocumentNumberGet(date?: string, operationType?: StockOperationType, options?: any): AxiosPromise<string> {
            return localVarFp.apiStockOperationDocumentNumberGet(date, operationType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationGet(options?: any): AxiosPromise<Array<StockOperationDto>> {
            return localVarFp.apiStockOperationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStockOperationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdGet(id: number, options?: any): AxiosPromise<StockOperationDetailsDto> {
            return localVarFp.apiStockOperationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationUpdateDto} [stockOperationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdPut(id: number, stockOperationUpdateDto?: StockOperationUpdateDto, options?: any): AxiosPromise<StockOperationDetailsDto> {
            return localVarFp.apiStockOperationIdPut(id, stockOperationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationIdStatusPut(id: number, status?: StockOperationStatus, options?: any): AxiosPromise<StockOperationDetailsDto> {
            return localVarFp.apiStockOperationIdStatusPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationLatestDraftGet(options?: any): AxiosPromise<Array<StockOperationDto>> {
            return localVarFp.apiStockOperationLatestDraftGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationLatestPublishedGet(options?: any): AxiosPromise<Array<StockOperationDto>> {
            return localVarFp.apiStockOperationLatestPublishedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationMinimalIdGet(id: number, options?: any): AxiosPromise<StockOperationMinimalDto> {
            return localVarFp.apiStockOperationMinimalIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationOperationsForDateGet(date?: string, options?: any): AxiosPromise<Array<StockOperationDto>> {
            return localVarFp.apiStockOperationOperationsForDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockOperationCreateDto} [stockOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationPost(stockOperationCreateDto?: StockOperationCreateDto, options?: any): AxiosPromise<StockOperationDetailsDto> {
            return localVarFp.apiStockOperationPost(stockOperationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockOperationCreateDto} [stockOperationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationProductionStockOperationPost(stockOperationCreateDto?: StockOperationCreateDto, options?: any): AxiosPromise<StockOperationDto> {
            return localVarFp.apiStockOperationProductionStockOperationPost(stockOperationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [documentNumber] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationValidateDocumentNumberGet(documentNumber?: string, id?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiStockOperationValidateDocumentNumberGet(documentNumber, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockOperationApi - object-oriented interface
 * @export
 * @class StockOperationApi
 * @extends {BaseAPI}
 */
export class StockOperationApi extends BaseAPI {
    /**
     * 
     * @param {number} [productId] 
     * @param {number} [warehouseId] 
     * @param {number} [stockOperationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationBatchNumbersGet(productId?: number, warehouseId?: number, stockOperationId?: number, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationBatchNumbersGet(productId, warehouseId, stockOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {StockOperationType} [operationType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationDocumentNumberGet(date?: string, operationType?: StockOperationType, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationDocumentNumberGet(date, operationType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationGet(options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationIdDelete(id: number, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationIdGet(id: number, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StockOperationUpdateDto} [stockOperationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationIdPut(id: number, stockOperationUpdateDto?: StockOperationUpdateDto, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationIdPut(id, stockOperationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StockOperationStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationIdStatusPut(id: number, status?: StockOperationStatus, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationIdStatusPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationLatestDraftGet(options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationLatestDraftGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationLatestPublishedGet(options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationLatestPublishedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationMinimalIdGet(id: number, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationMinimalIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationOperationsForDateGet(date?: string, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationOperationsForDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockOperationCreateDto} [stockOperationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationPost(stockOperationCreateDto?: StockOperationCreateDto, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationPost(stockOperationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockOperationCreateDto} [stockOperationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationProductionStockOperationPost(stockOperationCreateDto?: StockOperationCreateDto, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationProductionStockOperationPost(stockOperationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [documentNumber] 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationApi
     */
    public apiStockOperationValidateDocumentNumberGet(documentNumber?: string, id?: number, options?: any) {
        return StockOperationApiFp(this.configuration).apiStockOperationValidateDocumentNumberGet(documentNumber, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockOperationUpdateHistoryApi - axios parameter creator
 * @export
 */
export const StockOperationUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperationUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryHistoryIdGet', 'id', id)
            const localVarPath = `/api/StockOperationUpdateHistory/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet: async (id: number, warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet', 'id', id)
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/StockOperationUpdateHistory/history/product/{id}/reservation/{warehouseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet: async (id: number, warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet', 'id', id)
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/StockOperationUpdateHistory/history/product/{id}/{warehouseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryIdDelete', 'id', id)
            const localVarPath = `/api/StockOperationUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryIdGet', 'id', id)
            const localVarPath = `/api/StockOperationUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationUpdateHistoryUpdateDto} [stockOperationUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryIdPut: async (id: number, stockOperationUpdateHistoryUpdateDto?: StockOperationUpdateHistoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStockOperationUpdateHistoryIdPut', 'id', id)
            const localVarPath = `/api/StockOperationUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockOperationUpdateHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockOperationUpdateHistoryCreateDto} [stockOperationUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryPost: async (stockOperationUpdateHistoryCreateDto?: StockOperationUpdateHistoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StockOperationUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockOperationUpdateHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockOperationUpdateHistoryApi - functional programming interface
 * @export
 */
export const StockOperationUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockOperationUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet(id: number, warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockOperationUpdateHistoryDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet(id, warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet(id: number, warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockHistoryMinimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet(id, warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationUpdateHistoryUpdateDto} [stockOperationUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryIdPut(id: number, stockOperationUpdateHistoryUpdateDto?: StockOperationUpdateHistoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryIdPut(id, stockOperationUpdateHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StockOperationUpdateHistoryCreateDto} [stockOperationUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockOperationUpdateHistoryPost(stockOperationUpdateHistoryCreateDto?: StockOperationUpdateHistoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockOperationUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockOperationUpdateHistoryPost(stockOperationUpdateHistoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockOperationUpdateHistoryApi - factory interface
 * @export
 */
export const StockOperationUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockOperationUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryGet(options?: any): AxiosPromise<Array<StockOperationUpdateHistoryDto>> {
            return localVarFp.apiStockOperationUpdateHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryHistoryIdGet(id: number, options?: any): AxiosPromise<Array<StockOperationUpdateHistoryDto>> {
            return localVarFp.apiStockOperationUpdateHistoryHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet(id: number, warehouseId: number, options?: any): AxiosPromise<Array<StockOperationUpdateHistoryDetailsDto>> {
            return localVarFp.apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet(id, warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet(id: number, warehouseId: number, options?: any): AxiosPromise<Array<StockHistoryMinimalDto>> {
            return localVarFp.apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet(id, warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStockOperationUpdateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryIdGet(id: number, options?: any): AxiosPromise<StockOperationUpdateHistoryDetailsDto> {
            return localVarFp.apiStockOperationUpdateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StockOperationUpdateHistoryUpdateDto} [stockOperationUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryIdPut(id: number, stockOperationUpdateHistoryUpdateDto?: StockOperationUpdateHistoryUpdateDto, options?: any): AxiosPromise<StockOperationUpdateHistoryDetailsDto> {
            return localVarFp.apiStockOperationUpdateHistoryIdPut(id, stockOperationUpdateHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockOperationUpdateHistoryCreateDto} [stockOperationUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockOperationUpdateHistoryPost(stockOperationUpdateHistoryCreateDto?: StockOperationUpdateHistoryCreateDto, options?: any): AxiosPromise<StockOperationUpdateHistoryDetailsDto> {
            return localVarFp.apiStockOperationUpdateHistoryPost(stockOperationUpdateHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockOperationUpdateHistoryApi - object-oriented interface
 * @export
 * @class StockOperationUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class StockOperationUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryGet(options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryHistoryIdGet(id: number, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet(id: number, warehouseId: number, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryHistoryProductIdReservationWarehouseIdGet(id, warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet(id: number, warehouseId: number, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryHistoryProductIdWarehouseIdGet(id, warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryIdDelete(id: number, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryIdGet(id: number, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StockOperationUpdateHistoryUpdateDto} [stockOperationUpdateHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryIdPut(id: number, stockOperationUpdateHistoryUpdateDto?: StockOperationUpdateHistoryUpdateDto, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryIdPut(id, stockOperationUpdateHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockOperationUpdateHistoryCreateDto} [stockOperationUpdateHistoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockOperationUpdateHistoryApi
     */
    public apiStockOperationUpdateHistoryPost(stockOperationUpdateHistoryCreateDto?: StockOperationUpdateHistoryCreateDto, options?: any) {
        return StockOperationUpdateHistoryApiFp(this.configuration).apiStockOperationUpdateHistoryPost(stockOperationUpdateHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitOfMeasureApi - axios parameter creator
 * @export
 */
export const UnitOfMeasureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} baseUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet: async (baseUnitId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUnitId' is not null or undefined
            assertParamExists('apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet', 'baseUnitId', baseUnitId)
            const localVarPath = `/api/UnitOfMeasure/auxiliaryUnits/{baseUnitId}`
                .replace(`{${"baseUnitId"}}`, encodeURIComponent(String(baseUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureBaseUnitsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UnitOfMeasure/baseUnits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UnitOfMeasure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUnitOfMeasureIdDelete', 'id', id)
            const localVarPath = `/api/UnitOfMeasure/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUnitOfMeasureIdGet', 'id', id)
            const localVarPath = `/api/UnitOfMeasure/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UnitOfMeasureUpdateDto} [unitOfMeasureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureIdPut: async (id: number, unitOfMeasureUpdateDto?: UnitOfMeasureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUnitOfMeasureIdPut', 'id', id)
            const localVarPath = `/api/UnitOfMeasure/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitOfMeasureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnitOfMeasureCreateDto} [unitOfMeasureCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasurePost: async (unitOfMeasureCreateDto?: UnitOfMeasureCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UnitOfMeasure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitOfMeasureCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitOfMeasureApi - functional programming interface
 * @export
 */
export const UnitOfMeasureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitOfMeasureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} baseUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet(baseUnitId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitOfMeasureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet(baseUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasureBaseUnitsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitOfMeasureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasureBaseUnitsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasureGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitOfMeasureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasureGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasureIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasureIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasureIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitOfMeasureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasureIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UnitOfMeasureUpdateDto} [unitOfMeasureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasureIdPut(id: number, unitOfMeasureUpdateDto?: UnitOfMeasureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitOfMeasureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasureIdPut(id, unitOfMeasureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnitOfMeasureCreateDto} [unitOfMeasureCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnitOfMeasurePost(unitOfMeasureCreateDto?: UnitOfMeasureCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitOfMeasureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnitOfMeasurePost(unitOfMeasureCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitOfMeasureApi - factory interface
 * @export
 */
export const UnitOfMeasureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitOfMeasureApiFp(configuration)
    return {
        /**
         * 
         * @param {number} baseUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet(baseUnitId: number, options?: any): AxiosPromise<Array<UnitOfMeasureDto>> {
            return localVarFp.apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet(baseUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureBaseUnitsGet(options?: any): AxiosPromise<Array<UnitOfMeasureDto>> {
            return localVarFp.apiUnitOfMeasureBaseUnitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureGet(options?: any): AxiosPromise<Array<UnitOfMeasureDto>> {
            return localVarFp.apiUnitOfMeasureGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUnitOfMeasureIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureIdGet(id: number, options?: any): AxiosPromise<UnitOfMeasureDetailsDto> {
            return localVarFp.apiUnitOfMeasureIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UnitOfMeasureUpdateDto} [unitOfMeasureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasureIdPut(id: number, unitOfMeasureUpdateDto?: UnitOfMeasureUpdateDto, options?: any): AxiosPromise<UnitOfMeasureDetailsDto> {
            return localVarFp.apiUnitOfMeasureIdPut(id, unitOfMeasureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitOfMeasureCreateDto} [unitOfMeasureCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnitOfMeasurePost(unitOfMeasureCreateDto?: UnitOfMeasureCreateDto, options?: any): AxiosPromise<UnitOfMeasureDetailsDto> {
            return localVarFp.apiUnitOfMeasurePost(unitOfMeasureCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitOfMeasureApi - object-oriented interface
 * @export
 * @class UnitOfMeasureApi
 * @extends {BaseAPI}
 */
export class UnitOfMeasureApi extends BaseAPI {
    /**
     * 
     * @param {number} baseUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet(baseUnitId: number, options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasureAuxiliaryUnitsBaseUnitIdGet(baseUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasureBaseUnitsGet(options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasureBaseUnitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasureGet(options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasureGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasureIdDelete(id: number, options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasureIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasureIdGet(id: number, options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasureIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UnitOfMeasureUpdateDto} [unitOfMeasureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasureIdPut(id: number, unitOfMeasureUpdateDto?: UnitOfMeasureUpdateDto, options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasureIdPut(id, unitOfMeasureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitOfMeasureCreateDto} [unitOfMeasureCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasureApi
     */
    public apiUnitOfMeasurePost(unitOfMeasureCreateDto?: UnitOfMeasureCreateDto, options?: any) {
        return UnitOfMeasureApiFp(this.configuration).apiUnitOfMeasurePost(unitOfMeasureCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseApi - axios parameter creator
 * @export
 */
export const WarehouseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Warehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWarehouseIdDelete', 'id', id)
            const localVarPath = `/api/Warehouse/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWarehouseIdGet', 'id', id)
            const localVarPath = `/api/Warehouse/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {WarehouseUpdateDto} [warehouseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseIdPut: async (id: number, warehouseUpdateDto?: WarehouseUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWarehouseIdPut', 'id', id)
            const localVarPath = `/api/Warehouse/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseParentWarehousesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Warehouse/parentWarehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarehouseCreateDto} [warehouseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehousePost: async (warehouseCreateDto?: WarehouseCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Warehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseSubWarehousesWarehouseIdGet: async (warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiWarehouseSubWarehousesWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/Warehouse/subWarehouses/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseApi - functional programming interface
 * @export
 */
export const WarehouseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {WarehouseUpdateDto} [warehouseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseIdPut(id: number, warehouseUpdateDto?: WarehouseUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseIdPut(id, warehouseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseParentWarehousesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseParentWarehousesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarehouseCreateDto} [warehouseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehousePost(warehouseCreateDto?: WarehouseCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehousePost(warehouseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseSubWarehousesWarehouseIdGet(warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseSubWarehousesWarehouseIdGet(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseApi - factory interface
 * @export
 */
export const WarehouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseGet(options?: any): AxiosPromise<Array<WarehouseDto>> {
            return localVarFp.apiWarehouseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiWarehouseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseIdGet(id: number, options?: any): AxiosPromise<WarehouseDetailsDto> {
            return localVarFp.apiWarehouseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {WarehouseUpdateDto} [warehouseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseIdPut(id: number, warehouseUpdateDto?: WarehouseUpdateDto, options?: any): AxiosPromise<WarehouseDetailsDto> {
            return localVarFp.apiWarehouseIdPut(id, warehouseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseParentWarehousesGet(options?: any): AxiosPromise<Array<WarehouseDto>> {
            return localVarFp.apiWarehouseParentWarehousesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarehouseCreateDto} [warehouseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehousePost(warehouseCreateDto?: WarehouseCreateDto, options?: any): AxiosPromise<WarehouseDetailsDto> {
            return localVarFp.apiWarehousePost(warehouseCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseSubWarehousesWarehouseIdGet(warehouseId: number, options?: any): AxiosPromise<Array<WarehouseDto>> {
            return localVarFp.apiWarehouseSubWarehousesWarehouseIdGet(warehouseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseApi - object-oriented interface
 * @export
 * @class WarehouseApi
 * @extends {BaseAPI}
 */
export class WarehouseApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehouseGet(options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehouseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehouseIdDelete(id: number, options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehouseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehouseIdGet(id: number, options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehouseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {WarehouseUpdateDto} [warehouseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehouseIdPut(id: number, warehouseUpdateDto?: WarehouseUpdateDto, options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehouseIdPut(id, warehouseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehouseParentWarehousesGet(options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehouseParentWarehousesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseCreateDto} [warehouseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehousePost(warehouseCreateDto?: WarehouseCreateDto, options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehousePost(warehouseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public apiWarehouseSubWarehousesWarehouseIdGet(warehouseId: number, options?: any) {
        return WarehouseApiFp(this.configuration).apiWarehouseSubWarehousesWarehouseIdGet(warehouseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseUpdateHistoryApi - axios parameter creator
 * @export
 */
export const WarehouseUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WarehouseUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryHistoryWarehouseIdGet: async (warehouseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('apiWarehouseUpdateHistoryHistoryWarehouseIdGet', 'warehouseId', warehouseId)
            const localVarPath = `/api/WarehouseUpdateHistory/history/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWarehouseUpdateHistoryIdDelete', 'id', id)
            const localVarPath = `/api/WarehouseUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWarehouseUpdateHistoryIdGet', 'id', id)
            const localVarPath = `/api/WarehouseUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {WarehouseUpdateHistoryUpdateDto} [warehouseUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryIdPut: async (id: number, warehouseUpdateHistoryUpdateDto?: WarehouseUpdateHistoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWarehouseUpdateHistoryIdPut', 'id', id)
            const localVarPath = `/api/WarehouseUpdateHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseUpdateHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarehouseUpdateHistoryCreateDto} [warehouseUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryPost: async (warehouseUpdateHistoryCreateDto?: WarehouseUpdateHistoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WarehouseUpdateHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseUpdateHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseUpdateHistoryApi - functional programming interface
 * @export
 */
export const WarehouseUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseUpdateHistoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseUpdateHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseUpdateHistoryHistoryWarehouseIdGet(warehouseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseUpdateHistoryHistoryWarehouseIdGet(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseUpdateHistoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseUpdateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseUpdateHistoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseUpdateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {WarehouseUpdateHistoryUpdateDto} [warehouseUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseUpdateHistoryIdPut(id: number, warehouseUpdateHistoryUpdateDto?: WarehouseUpdateHistoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseUpdateHistoryIdPut(id, warehouseUpdateHistoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarehouseUpdateHistoryCreateDto} [warehouseUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWarehouseUpdateHistoryPost(warehouseUpdateHistoryCreateDto?: WarehouseUpdateHistoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseUpdateHistoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWarehouseUpdateHistoryPost(warehouseUpdateHistoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseUpdateHistoryApi - factory interface
 * @export
 */
export const WarehouseUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryGet(options?: any): AxiosPromise<Array<WarehouseUpdateHistoryDto>> {
            return localVarFp.apiWarehouseUpdateHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryHistoryWarehouseIdGet(warehouseId: number, options?: any): AxiosPromise<Array<WarehouseUpdateHistoryDto>> {
            return localVarFp.apiWarehouseUpdateHistoryHistoryWarehouseIdGet(warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiWarehouseUpdateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryIdGet(id: number, options?: any): AxiosPromise<WarehouseUpdateHistoryDetailsDto> {
            return localVarFp.apiWarehouseUpdateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {WarehouseUpdateHistoryUpdateDto} [warehouseUpdateHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryIdPut(id: number, warehouseUpdateHistoryUpdateDto?: WarehouseUpdateHistoryUpdateDto, options?: any): AxiosPromise<WarehouseUpdateHistoryDetailsDto> {
            return localVarFp.apiWarehouseUpdateHistoryIdPut(id, warehouseUpdateHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarehouseUpdateHistoryCreateDto} [warehouseUpdateHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWarehouseUpdateHistoryPost(warehouseUpdateHistoryCreateDto?: WarehouseUpdateHistoryCreateDto, options?: any): AxiosPromise<WarehouseUpdateHistoryDetailsDto> {
            return localVarFp.apiWarehouseUpdateHistoryPost(warehouseUpdateHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseUpdateHistoryApi - object-oriented interface
 * @export
 * @class WarehouseUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class WarehouseUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseUpdateHistoryApi
     */
    public apiWarehouseUpdateHistoryGet(options?: any) {
        return WarehouseUpdateHistoryApiFp(this.configuration).apiWarehouseUpdateHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} warehouseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseUpdateHistoryApi
     */
    public apiWarehouseUpdateHistoryHistoryWarehouseIdGet(warehouseId: number, options?: any) {
        return WarehouseUpdateHistoryApiFp(this.configuration).apiWarehouseUpdateHistoryHistoryWarehouseIdGet(warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseUpdateHistoryApi
     */
    public apiWarehouseUpdateHistoryIdDelete(id: number, options?: any) {
        return WarehouseUpdateHistoryApiFp(this.configuration).apiWarehouseUpdateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseUpdateHistoryApi
     */
    public apiWarehouseUpdateHistoryIdGet(id: number, options?: any) {
        return WarehouseUpdateHistoryApiFp(this.configuration).apiWarehouseUpdateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {WarehouseUpdateHistoryUpdateDto} [warehouseUpdateHistoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseUpdateHistoryApi
     */
    public apiWarehouseUpdateHistoryIdPut(id: number, warehouseUpdateHistoryUpdateDto?: WarehouseUpdateHistoryUpdateDto, options?: any) {
        return WarehouseUpdateHistoryApiFp(this.configuration).apiWarehouseUpdateHistoryIdPut(id, warehouseUpdateHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseUpdateHistoryCreateDto} [warehouseUpdateHistoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseUpdateHistoryApi
     */
    public apiWarehouseUpdateHistoryPost(warehouseUpdateHistoryCreateDto?: WarehouseUpdateHistoryCreateDto, options?: any) {
        return WarehouseUpdateHistoryApiFp(this.configuration).apiWarehouseUpdateHistoryPost(warehouseUpdateHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


